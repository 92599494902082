import {
  DeviceValidationItem,
  DeviceValidationItemKeys,
  FormattedDeviceType,
} from "../types";
import { MonthlyInvoiceSpendingTotalItem } from "./types";

export function getCarrierAccountTotalsByFAN(
  data: DeviceValidationItem[],
  carrier: string,
  fanAccount: string
): MonthlyInvoiceSpendingTotalItem[] {
  // Filter data for the specified carrier and FAN
  const fanData = data.filter(
    (item) =>
      item.carrier_name === carrier && item.foundation_account === fanAccount
  );

  // Get all unique carrier account numbers for this FAN
  const carrierAccounts = [
    ...new Set(fanData.map((item) => item.carrier_account_number)),
  ];

  return (
    carrierAccounts
      .map((currentAccount) => {
        // Group data by carrier account number
        const accountData = fanData.filter(
          (item) => item.carrier_account_number === currentAccount
        );

        // Initialize accumulator object with default values
        const accumulator: MonthlyInvoiceSpendingTotalItem = {
          label: currentAccount,
          Smart: 0,
          Phone: 0,
          Data: 0,
          Tablet: 0,
          Wearable: 0,
          Pager: 0,
          deviceCount: 0,
          equipmentCharges: 0,
          calculatedMrc: 0,
          calculatedUsageCharges: 0,
          calculatedTaxes: 0,
          calculatedFees: 0,
          calculatedOtherCharges: 0,
          totalCharges: 0,
        };

        // Reduce the data into the accumulator
        return accountData.reduce((acc, item) => {
          // Skip account level rows for device count
          if (!item[DeviceValidationItemKeys.IsAccountLevelSummary]) {
            acc.deviceCount += item.device_count;
          }

          // Add device type charges with rounding
          const deviceType = item.formatted_device_type as FormattedDeviceType;
          acc[deviceType] = Number(
            (acc[deviceType] + (item.total_charges ?? 0)).toFixed(2)
          );

          // Add other metrics with rounding
          acc.equipmentCharges = Number(
            (acc.equipmentCharges + (item.equipment_charges ?? 0)).toFixed(2)
          );
          acc.calculatedMrc = Number(
            (acc.calculatedMrc + (item.calculated_mrc ?? 0)).toFixed(2)
          );
          acc.calculatedUsageCharges = Number(
            (
              acc.calculatedUsageCharges + (item.calculated_usage_charges ?? 0)
            ).toFixed(2)
          );
          acc.calculatedTaxes = Number(
            (acc.calculatedTaxes + (item.calculated_taxes ?? 0)).toFixed(2)
          );
          acc.calculatedFees = Number(
            (acc.calculatedFees + (item.calculated_fees ?? 0)).toFixed(2)
          );
          acc.calculatedOtherCharges = Number(
            (
              acc.calculatedOtherCharges + (item.calculated_other_charges ?? 0)
            ).toFixed(2)
          );
          acc.totalCharges = Number(
            (acc.totalCharges + (item.total_charges ?? 0)).toFixed(2)
          );

          return acc;
        }, accumulator);
      })
      // sort by total charges
      .sort((a, b) => b.totalCharges - a.totalCharges)
  );
}

export function getFANTotalsByCarrier(
  data: DeviceValidationItem[],
  carrier: string
): MonthlyInvoiceSpendingTotalItem[] {
  // Filter data for the specified carrier
  const carrierData = data.filter((item) => item.carrier_name === carrier);

  // Get all unique foundation accounts for this carrier
  const foundationAccounts = [
    ...new Set(carrierData.map((item) => item.foundation_account)),
  ];

  return (
    foundationAccounts
      .map((currentFAN) => {
        // Group data by foundation account
        const fanData = carrierData.filter(
          (item) => item.foundation_account === currentFAN
        );

        // Initialize accumulator object with default values
        const accumulator: MonthlyInvoiceSpendingTotalItem = {
          label: currentFAN,
          Smart: 0,
          Phone: 0,
          Data: 0,
          Tablet: 0,
          Wearable: 0,
          Pager: 0,
          deviceCount: 0,
          equipmentCharges: 0,
          calculatedMrc: 0,
          calculatedUsageCharges: 0,
          calculatedTaxes: 0,
          calculatedFees: 0,
          calculatedOtherCharges: 0,
          totalCharges: 0,
        };

        // Reduce the data into the accumulator
        return fanData.reduce((acc, item) => {
          // Skip account level rows for device count
          if (!item[DeviceValidationItemKeys.IsAccountLevelSummary]) {
            acc.deviceCount += item.device_count;
          }

          // Add device type charges with rounding
          const deviceType = item.formatted_device_type as FormattedDeviceType;
          acc[deviceType] = Number(
            (acc[deviceType] + (item.total_charges ?? 0)).toFixed(2)
          );

          // Add other metrics with rounding
          acc.equipmentCharges = Number(
            (acc.equipmentCharges + (item.equipment_charges ?? 0)).toFixed(2)
          );
          acc.calculatedMrc = Number(
            (acc.calculatedMrc + (item.calculated_mrc ?? 0)).toFixed(2)
          );
          acc.calculatedUsageCharges = Number(
            (
              acc.calculatedUsageCharges + (item.calculated_usage_charges ?? 0)
            ).toFixed(2)
          );
          acc.calculatedTaxes = Number(
            (acc.calculatedTaxes + (item.calculated_taxes ?? 0)).toFixed(2)
          );
          acc.calculatedFees = Number(
            (acc.calculatedFees + (item.calculated_fees ?? 0)).toFixed(2)
          );
          acc.calculatedOtherCharges = Number(
            (
              acc.calculatedOtherCharges + (item.calculated_other_charges ?? 0)
            ).toFixed(2)
          );
          acc.totalCharges = Number(
            (acc.totalCharges + (item.total_charges ?? 0)).toFixed(2)
          );

          return acc;
        }, accumulator);
      })
      // sort by total charges
      .sort((a, b) => b.totalCharges - a.totalCharges)
  );
}

/**
 * This function will get the totals a given carrier
 */
export function getCarriersTotals(
  data: DeviceValidationItem[]
): MonthlyInvoiceSpendingTotalItem[] {
  // Get all unique carriers if no specific carrier is provided
  const carriersToProcess = [...new Set(data.map((item) => item.carrier_name))];

  return carriersToProcess.map((currentCarrier) => {
    // Group data by carrier
    const carrierData = data.filter(
      (item) => item.carrier_name === currentCarrier
    );

    // Initialize accumulator object with default values
    const accumulator: MonthlyInvoiceSpendingTotalItem = {
      label: currentCarrier,
      Smart: 0,
      Phone: 0,
      Data: 0,
      Tablet: 0,
      Wearable: 0,
      Pager: 0,
      deviceCount: 0,
      equipmentCharges: 0,
      calculatedMrc: 0,
      calculatedUsageCharges: 0,
      calculatedTaxes: 0,
      calculatedFees: 0,
      calculatedOtherCharges: 0,
      totalCharges: 0,
    };

    // Reduce the data into the accumulator
    return carrierData.reduce((acc, item) => {
      // Skip account level rows for device count
      if (!item[DeviceValidationItemKeys.IsAccountLevelSummary]) {
        acc.deviceCount += item.device_count;
      }

      // Add device type charges with rounding
      const deviceType = item.formatted_device_type as FormattedDeviceType;
      acc[deviceType] = Number(
        (acc[deviceType] + (item.total_charges ?? 0)).toFixed(2)
      );

      // Add other metrics with rounding
      acc.equipmentCharges = Number(
        (acc.equipmentCharges + (item.equipment_charges ?? 0)).toFixed(2)
      );
      acc.calculatedMrc = Number(
        (acc.calculatedMrc + (item.calculated_mrc ?? 0)).toFixed(2)
      );
      acc.calculatedUsageCharges = Number(
        (
          acc.calculatedUsageCharges + (item.calculated_usage_charges ?? 0)
        ).toFixed(2)
      );
      acc.calculatedTaxes = Number(
        (acc.calculatedTaxes + (item.calculated_taxes ?? 0)).toFixed(2)
      );
      acc.calculatedFees = Number(
        (acc.calculatedFees + (item.calculated_fees ?? 0)).toFixed(2)
      );
      acc.calculatedOtherCharges = Number(
        (
          acc.calculatedOtherCharges + (item.calculated_other_charges ?? 0)
        ).toFixed(2)
      );
      acc.totalCharges = Number(
        (acc.totalCharges + (item.total_charges ?? 0)).toFixed(2)
      );

      return acc;
    }, accumulator);
  });
}

export function getMinMaxValues(
  monthlySpendingRows: MonthlyInvoiceSpendingTotalItem[]
) {
  // we want any device related values (e.g., "Data", "Smart", "Phone", etc.)
  // we do not want any other values (e.g., "deviceCount", "totalCharges", etc.)

  let min = 0;
  let max = 0;

  monthlySpendingRows.forEach((row) => {
    let rowMin = 0;
    let rowMax = 0;

    const rowDeviceValues: number[] = [];

    if (row.Data) {
      rowDeviceValues.push(row.Data);
    }
    if (row.Smart) {
      rowDeviceValues.push(row.Smart);
    }
    if (row.Phone) {
      rowDeviceValues.push(row.Phone);
    }
    if (row.Tablet) {
      rowDeviceValues.push(row.Tablet);
    }
    if (row.Wearable) {
      rowDeviceValues.push(row.Wearable);
    }
    if (row.Pager) {
      rowDeviceValues.push(row.Pager);
    }

    const negativeValues = rowDeviceValues.filter((value) => value < 0);
    const positiveValues = rowDeviceValues.filter((value) => value >= 0);

    rowMin = negativeValues.reduce((acc, value) => acc + value, 0);
    rowMax = positiveValues.reduce((acc, value) => acc + value, 0);

    if (rowMin < min) {
      min = rowMin;
    }
    if (rowMax > max) {
      max = rowMax;
    }
  });

  return {
    min,
    max,
  };
}

/**
 * This function will produce a list of currency labels
 * based on the min and max value.
 * It will always include "$0" and the max value.
 * Intermediate values are rounded to nearest 10k.
 */
export function getCurrencyLabels(minValue: number, maxValue: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  // Determine the appropriate step size based on maxValue
  const getStepSize = (value: number) => {
    if (value <= 100) return 10;
    if (value <= 1000) return 100;
    if (value <= 10000) return 1000;
    if (value <= 100000) return 10000;
    if (value <= 1000000) return 100000;
    return 1000000;
  };

  const stepSize = getStepSize(
    Math.max(Math.abs(minValue), Math.abs(maxValue))
  );

  // Round min and max values to the step size
  const roundedMin =
    minValue < 0 ? Math.floor(minValue / stepSize) * stepSize : 0;
  const roundedMax = Math.ceil(maxValue / stepSize) * stepSize;

  const labels: number[] = [];

  if (roundedMin < 0) {
    // Calculate number of steps (25% for negative, 75% for positive)
    const totalSteps = 8; // Adjust this number for more/fewer labels
    const negativeSteps = Math.max(2, Math.floor(totalSteps * 0.25));
    const positiveSteps = totalSteps - negativeSteps;

    // Add negative labels
    const negativeStep = Math.abs(roundedMin) / negativeSteps;
    for (let i = negativeSteps; i > 0; i--) {
      labels.push(roundedMin + (negativeSteps - i) * negativeStep);
    }

    // Add zero
    labels.push(0);

    // Add positive labels
    const positiveStep = roundedMax / positiveSteps;
    for (let i = 1; i <= positiveSteps; i++) {
      labels.push(i * positiveStep);
    }
  } else {
    // For positive-only ranges
    labels.push(0);
    for (let value = stepSize; value < roundedMax; value += stepSize) {
      labels.push(value);
    }
    labels.push(roundedMax);
  }

  // Format all numbers as currency
  return labels.map((num) => formatter.format(num));
}

const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function getCurrencyFormat(value: number) {
  return currencyFormat.format(value);
}
