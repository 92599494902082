const ChartLegendLabel = ({
  color,
  label,
}: {
  color: string;
  label: string;
}) => {
  return (
    <div className="flex flex-row gap-1">
      <div className="w-4 h-4" style={{ backgroundColor: color }} />
      <div className="text-xs">{label}</div>
    </div>
  );
};

export default ChartLegendLabel;
