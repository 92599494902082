const SpendingTotalCellTotal = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-1 justify-end text-xs p-2 font-bold">
      {children}
    </div>
  );
};

export default SpendingTotalCellTotal;
