import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { DeviceValidationItem, DeviceValidationItemKeys } from "../types";
import DeviceTypeChartBar from "./DeviceTypeChartBar";
import FANSelect from "./FANSelect";
import ScrollIndicator from "./ScrolllIndicator";
import SpendingTotalCell from "./SpendingTotalCell";
import SpendingTotalCellHeader from "./SpendingTotalCellHeader";
import TableFooter from "./TableFooter";
import {
  deviceTypeChartColors,
  deviceTypeLabels,
  deviceTypeOrder,
} from "./constants";
import { MonthlyInvoiceSpendingTotalItem } from "./types";
import {
  getCarrierAccountTotalsByFAN,
  getCarriersTotals,
  getCurrencyFormat,
  getCurrencyLabels,
  getFANTotalsByCarrier,
  getMinMaxValues,
} from "./utils";
import { ALL_ENTRY } from "../constants";

export default function MonthlyInvoiceSpendingTotals({
  data,
  carrier,
  statementMonth,
  isLoading,
  selectedFan,
  onFanChange,
}: {
  data: DeviceValidationItem[];
  carrier: string;
  statementMonth: string;
  isLoading: boolean;
  selectedFan: string;
  onFanChange: (fan: string) => void;
}) {
  const [negativeValuesMap, setNegativeValuesMap] = useState<
    Record<string, number>
  >({});

  const fanAccounts: string[] = useMemo(() => {
    if (carrier === ALL_ENTRY) return [];

    return data
      .filter((item) => item[DeviceValidationItemKeys.CarrierName] === carrier)
      .map((item) => item[DeviceValidationItemKeys.FoundationAccount])
      .filter((fan, index, self) => self.indexOf(fan) === index)
      .sort((a, b) => a.localeCompare(b));
  }, [data, carrier]);

  const totalsList = useMemo(() => {
    if (carrier === ALL_ENTRY) {
      return getCarriersTotals(data);
    }

    if (selectedFan === ALL_ENTRY) {
      return getFANTotalsByCarrier(data, carrier);
    }

    return getCarrierAccountTotalsByFAN(data, carrier, selectedFan);
  }, [carrier, data, selectedFan]);

  const getPositiveDevicesList = (item: MonthlyInvoiceSpendingTotalItem) => {
    const positiveDevices = deviceTypeOrder.filter((key) => {
      const value = item[key as keyof typeof item] as number;
      return value > 0;
    });

    return positiveDevices
      .map((key) => ({
        label: key,
        value: item[key as keyof typeof item] as number,
      }))
      .sort((a, b) => b.value - a.value);
  };

  const getNegativeDevicesList = (item: MonthlyInvoiceSpendingTotalItem) => {
    const negativeDevices = deviceTypeOrder.filter((key) => {
      const value = item[key as keyof typeof item] as number;
      return value < 0;
    });

    return negativeDevices
      .map((key) => ({
        label: key,
        value: item[key as keyof typeof item] as number,
      }))
      .sort((a, b) => b.value - a.value);
  };

  const { min: minValue, max: maxValue } = getMinMaxValues(totalsList);

  const currencyLabels = useMemo(() => {
    if (
      isNaN(minValue) ||
      !isFinite(minValue) ||
      isNaN(maxValue) ||
      !isFinite(maxValue)
    )
      return [];

    return getCurrencyLabels(minValue, maxValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrier, statementMonth, minValue, maxValue, selectedFan]);

  const showScrollIndicator = totalsList.length > 6;

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrollState, setScrollState] = useState({
    showUp: false,
    showDown: false,
  });

  useEffect(() => {
    if (scrollContainerRef.current) {
      const element = scrollContainerRef.current;
      const hasScroll = element.scrollHeight > element.clientHeight;
      setScrollState((prev) => ({
        ...prev,
        showDown: hasScroll,
      }));
    }
  }, [totalsList]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.currentTarget;
    const showUp = element.scrollTop > 0;
    const showDown =
      element.scrollTop < element.scrollHeight - element.clientHeight;
    setScrollState({ showUp, showDown });
  };

  const aggregateTotals: MonthlyInvoiceSpendingTotalItem = useMemo(() => {
    return totalsList.reduce((acc, item) => {
      return {
        ...acc,
        label: "Total",
        deviceCount: (acc.deviceCount || 0) + item.deviceCount,
        equipmentCharges: (acc.equipmentCharges || 0) + item.equipmentCharges,
        calculatedMrc: (acc.calculatedMrc || 0) + item.calculatedMrc,
        calculatedUsageCharges:
          (acc.calculatedUsageCharges || 0) + item.calculatedUsageCharges,
        calculatedTaxes: (acc.calculatedTaxes || 0) + item.calculatedTaxes,
        calculatedFees: (acc.calculatedFees || 0) + item.calculatedFees,
        calculatedOtherCharges:
          (acc.calculatedOtherCharges || 0) + item.calculatedOtherCharges,
        totalCharges: (acc.totalCharges || 0) + item.totalCharges,
        Smart: (acc.Smart || 0) + item.Smart,
        Phone: (acc.Phone || 0) + item.Phone,
        Data: (acc.Data || 0) + item.Data,
        Tablet: (acc.Tablet || 0) + item.Tablet,
        Wearable: (acc.Wearable || 0) + item.Wearable,
        Pager: (acc.Pager || 0) + item.Pager,
      };
    }, {} as MonthlyInvoiceSpendingTotalItem);
  }, [totalsList]);

  const totalNegativeWidth = useMemo(() => {
    if (minValue >= 0) return 0;
    return Math.max(
      25,
      Object.values(negativeValuesMap).reduce((acc, width) => acc + width, 0)
    );
  }, [negativeValuesMap, minValue]);

  const totalPositiveWidth = useMemo(() => {
    return 100 - totalNegativeWidth;
  }, [totalNegativeWidth]);

  useEffect(() => {
    setNegativeValuesMap({});
  }, [carrier, statementMonth]);

  if (data.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <Card>
        <CardHeader title="Monthly Invoice Spending Totals" />
        <CardContent>
          {isLoading ? (
            <div className="flex flex-col gap-2">
              <Skeleton variant="rectangular" height={28} />
              <div className="grid grid-cols-2 gap-2">
                {[...Array(6)].map((_, index) => (
                  <Skeleton key={index} variant="rectangular" height={20} />
                ))}
              </div>
            </div>
          ) : (
            <div className="flex flex-col relative bg-slate-50 rounded-md">
              <div className="flex flex-row bg-primary-800 text-white rounded-t-md">
                <div className="flex flex-row w-1/2">
                  <div className="w-32 flex flex-row items-center pl-1">
                    {fanAccounts.length > 0 && (
                      <FANSelect
                        fanAccounts={fanAccounts}
                        selectedFan={selectedFan}
                        handleFanChange={(value) => onFanChange(value)}
                      />
                    )}
                  </div>

                  {currencyLabels.map((label, index) => (
                    <div key={index} className="flex flex-1 text-xs p-2">
                      {label}
                    </div>
                  ))}
                </div>
                <div className="flex flex-row w-1/2">
                  <SpendingTotalCellHeader>CNT</SpendingTotalCellHeader>
                  <SpendingTotalCellHeader>EQP$</SpendingTotalCellHeader>
                  <SpendingTotalCellHeader>MRC$</SpendingTotalCellHeader>
                  <SpendingTotalCellHeader>USAGE$</SpendingTotalCellHeader>
                  <SpendingTotalCellHeader>TAX$</SpendingTotalCellHeader>
                  <SpendingTotalCellHeader>FEES$</SpendingTotalCellHeader>
                  <SpendingTotalCellHeader>OCC$</SpendingTotalCellHeader>
                  <SpendingTotalCellHeader>TOTAL$</SpendingTotalCellHeader>
                </div>
              </div>

              {showScrollIndicator && <ScrollIndicator {...scrollState} />}

              <div
                ref={scrollContainerRef}
                className="max-h-40 overflow-y-auto relative"
                onScroll={handleScroll}
              >
                {totalsList
                  .sort((a, b) => b.totalCharges - a.totalCharges)
                  .map((item) => (
                    <div key={item.label} className="flex flex-row mb-1">
                      <div className="flex flex-row items-center w-1/2 gap-1">
                        <div className="text-right w-32 font-semibold text-xs">
                          {item.label}
                        </div>
                        <div className="flex flex-row flex-1 w-full gap-[1px]">
                          <div
                            className="flex flex-row justify-end"
                            style={{ width: `${totalNegativeWidth}%` }}
                          >
                            {getNegativeDevicesList(item).map((device) => (
                              <DeviceTypeChartBar
                                key={device.label}
                                maxNegativeValue={minValue}
                                maxPositiveValue={maxValue}
                                value={device.value}
                                color={deviceTypeChartColors[device.label]}
                                label1={deviceTypeLabels[device.label]}
                                label2={item.label}
                              />
                            ))}
                          </div>

                          <div className="w-[2px] h-4 bg-primary-900 mx-1" />

                          <div
                            className="flex flex-row justify-start gap-[1px]"
                            style={{ width: `${totalPositiveWidth}%` }}
                          >
                            {getPositiveDevicesList(item).map((device) => (
                              <DeviceTypeChartBar
                                key={device.label}
                                maxNegativeValue={minValue}
                                maxPositiveValue={maxValue}
                                value={device.value}
                                color={deviceTypeChartColors[device.label]}
                                label1={deviceTypeLabels[device.label]}
                                label2={item.label}
                              />
                            ))}
                          </div>
                        </div>
                      </div>

                      <div
                        className={`flex flex-row w-1/2 ${
                          totalsList.indexOf(item) % 2 === 1
                            ? "bg-slate-200"
                            : ""
                        }`}
                      >
                        <SpendingTotalCell>
                          {new Intl.NumberFormat("en-US").format(
                            item.deviceCount
                          )}
                        </SpendingTotalCell>
                        <SpendingTotalCell>
                          {getCurrencyFormat(item.equipmentCharges)}
                        </SpendingTotalCell>
                        <SpendingTotalCell>
                          {getCurrencyFormat(item.calculatedMrc)}
                        </SpendingTotalCell>
                        <SpendingTotalCell>
                          {getCurrencyFormat(item.calculatedUsageCharges)}
                        </SpendingTotalCell>
                        <SpendingTotalCell>
                          {getCurrencyFormat(item.calculatedTaxes)}
                        </SpendingTotalCell>
                        <SpendingTotalCell>
                          {getCurrencyFormat(item.calculatedFees)}
                        </SpendingTotalCell>
                        <SpendingTotalCell>
                          {getCurrencyFormat(item.calculatedOtherCharges)}
                        </SpendingTotalCell>
                        <SpendingTotalCell>
                          {getCurrencyFormat(item.totalCharges)}
                        </SpendingTotalCell>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      <TableFooter isLoading={isLoading} aggregateTotals={aggregateTotals} />
    </div>
  );
}
