import { Card } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { uniqueId } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AccountChargesTable from "./AccountChargesTable";
import { fetchDeviceValidations, fetchStatementMonths } from "./api";
import BillValidationHeader from "./BillValidationHeader";
import CarrierTabs from "./CarrierTabs";
import { AvailableCarriers, StatementMonthsResponse } from "./types";
import MonthlyInvoiceSpendingTotals from "./MonthlyInvoiceSpendingTotals";
import { ALL_ENTRY } from "./constants";

const BillValidation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [selectedDate, setSelectedDate] = useState(
    queryParams.get("date") || ""
  );
  const [selectedCarrier, setSelectedCarrier] = useState(
    queryParams.get("carrier") || ALL_ENTRY
  );
  const [selectedFan, setSelectedFan] = useState<string>(
    queryParams.get("fan") || ALL_ENTRY
  );

  const { data: deviceValidations = [], isLoading: loadingChartData } =
    useQuery({
      queryKey: ["deviceValidation", selectedDate],
      queryFn: async () => {
        if (!selectedDate) return [];
        try {
          const data = await fetchDeviceValidations(selectedDate);

          return data.map((item) => ({
            ...item,
            _id: uniqueId(),
          }));
        } catch (error) {
          Sentry.captureException(error);
          toast.error("There was an error fetching the report data");
          throw error;
        }
      },
      enabled: !!selectedDate,
    });

  const { data: statementMonthsData, isLoading: loadingMonths } = useQuery({
    queryKey: ["statementMonths"],
    queryFn: async () => {
      try {
        const allMonthsData = await fetchStatementMonths();
        return allMonthsData.map((item) => item.statement_month);
      } catch (error) {
        Sentry.captureException(error);
        toast.error("There was an error fetching the statement months");
        throw error;
      }
    },
  });

  const updateQueryParams = useCallback(
    (date?: string, carrier?: string, fan?: string) => {
      const newParams = new URLSearchParams(location.search);

      if (date !== undefined) {
        newParams.set("date", date);
      }

      if (carrier !== undefined) {
        newParams.set("carrier", carrier);
      }

      if (fan !== undefined) {
        newParams.set("fan", fan);
      }

      navigate({ search: newParams.toString() }, { replace: true });
    },
    [location.search, navigate]
  );

  const handleDateChange = useCallback(
    (newDate: string) => {
      setSelectedDate(newDate);
      updateQueryParams(newDate, undefined, undefined);
    },
    [updateQueryParams]
  );

  const handleCarrierChange = useCallback(
    (newCarrier: string) => {
      setSelectedCarrier(newCarrier);
      updateQueryParams(undefined, newCarrier, undefined);
    },
    [updateQueryParams]
  );

  const handleFanChange = useCallback(
    (newFan: string) => {
      setSelectedFan(newFan);
      updateQueryParams(undefined, undefined, newFan);
    },
    [updateQueryParams]
  );

  const carrierMap = useMemo(() => {
    return deviceValidations.reduce((acc, item) => {
      acc[item.carrier_name] = item.carrier_id;
      return acc;
    }, {} as Record<string, number>);
  }, [deviceValidations]);

  const filteredData = useMemo(() => {
    if (selectedCarrier === ALL_ENTRY) return deviceValidations;
    return deviceValidations.filter(
      (item) => item.carrier_name === selectedCarrier
    );
  }, [selectedCarrier, deviceValidations]);

  const availableMonths = (
    Array.isArray(statementMonthsData) ? statementMonthsData : []
  ).sort((a, b) => b.localeCompare(a));

  // does not include "All"
  const availableSpecifiedCarriers = useMemo(() => {
    return Object.keys(carrierMap);
  }, [carrierMap]);

  const availableCarriers: AvailableCarriers[] = useMemo(() => {
    return [
      "All",
      ...availableSpecifiedCarriers.sort((a, b) => a.localeCompare(b)),
    ];
  }, [availableSpecifiedCarriers]);

  const selectedCarrierId = useMemo(() => {
    return carrierMap[selectedCarrier];
  }, [selectedCarrier, carrierMap]);

  // if selectedCarrier not available, set it to "All"
  useEffect(() => {
    if (
      availableSpecifiedCarriers.length > 0 &&
      !availableSpecifiedCarriers.includes(selectedCarrier)
    ) {
      handleCarrierChange(ALL_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSpecifiedCarriers, selectedCarrier]);

  // Add this effect to handle auto-selection of the last available month
  useEffect(() => {
    if (!selectedDate && availableMonths.length > 0) {
      const lastMonth = availableMonths[0]; // First item since they're sorted descending
      handleDateChange(lastMonth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableMonths, selectedDate, handleDateChange]);

  // Add new effect to handle invalid fan selection only after data is loaded
  useEffect(() => {
    if (deviceValidations.length > 0) {
      const availableFans = deviceValidations.map(
        (item) => item.foundation_account
      );
      if (selectedFan !== ALL_ENTRY && !availableFans.includes(selectedFan)) {
        handleFanChange(ALL_ENTRY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceValidations, selectedFan]);

  // Reset the fan to "all" if the carrier or statement month changes
  useEffect(() => {
    if (deviceValidations.length > 0) {
      handleFanChange(ALL_ENTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCarrier]);

  return (
    <div className="p-4">
      <BillValidationHeader
        selectedDate={selectedDate}
        availableMonths={availableMonths}
        onMonthChange={handleDateChange}
        loadingMonths={loadingMonths}
        selectedCarrierId={selectedCarrierId}
      />

      <CarrierTabs
        selectedCarrier={selectedCarrier}
        onCarrierChange={handleCarrierChange}
        availableCarriers={availableCarriers}
        isLoading={loadingChartData}
      />

      <MonthlyInvoiceSpendingTotals
        data={filteredData}
        isLoading={loadingChartData}
        carrier={selectedCarrier}
        statementMonth={selectedDate}
        selectedFan={selectedFan}
        onFanChange={handleFanChange}
      />

      <Card className="mt-4 p-4">
        <AccountChargesTable
          data={filteredData}
          isLoading={loadingChartData}
          selectedFan={selectedFan}
        />
      </Card>
    </div>
  );
};

export default BillValidation;
