interface CarrierStatementMonth {
  carrier_id: number;
  statement_month: string;
}
export interface CarrierStatementMonthsMap {
  [carrierId: string]: CarrierStatementMonth[];
}

export type StatementMonthsResponse = CarrierStatementMonthsMap & {
  all_months: {
    statement_month: string;
  }[];
};

export enum DeviceValidationItemKeys {
  EquipmentTax = "equipment_tax",
  TerminationCharge = "termination_charge",
  MmsCharges = "mms_charges",
  CarrierId = "carrier_id",
  CalculatedOtherCharges = "calculated_other_charges",
  MonthlyAccess = "monthly_access",
  CarrierName = "carrier_name",
  PdaCharges = "pda_charges",
  DownloadCharges = "download_charges",
  NetFeatureCharges = "net_feature_charges",
  AirtimeCharges = "airtime_charges",
  CalculatedEquipmentCharges = "calculated_equipment_charges",
  FoundationAccount = "foundation_account",
  RoamCharges = "roam_charges",
  DeviceCount = "device_count",
  LdCharges = "ld_charges",
  ActivationCharge = "activation_charge",
  KbCharges = "kb_charges",
  TotalTax = "total_tax",
  EquipmentCharges = "equipment_charges",
  SmsCharges = "sms_charges",
  StatementMonth = "statement_month",
  CalculatedTaxes = "calculated_taxes",
  CalculatedFees = "calculated_fees",
  TotalCharges = "total_charges",
  CalculatedUsageCharges = "calculated_usage_charges",
  CalculatedMrc = "calculated_mrc",
  PeerToPeerCharges = "peer_to_peer_charges",
  ContractPlanDiscounts = "contract_plan_discounts",
  FormattedDeviceType = "formatted_device_type",
  CarrierAccountNumber = "carrier_account_number",
  IsAccountLevelSummary = "is_account_level_summary",
}

export const DeviceValidationCostKeys = [DeviceValidationItemKeys.TotalCharges];

export enum FormattedDeviceType {
  Smart = "Smart",
  Phone = "Phone",
  Data = "Data",
  Tablet = "Tablet",
  Pager = "Pager",
  Wearable = "Wearable",
}

export type DeviceValidationItem = {
  _id?: string;
  [DeviceValidationItemKeys.EquipmentTax]: number | null;
  [DeviceValidationItemKeys.TerminationCharge]: number | null;
  [DeviceValidationItemKeys.MmsCharges]: number | null;
  [DeviceValidationItemKeys.CarrierId]: number;
  [DeviceValidationItemKeys.CalculatedOtherCharges]: number | null;
  [DeviceValidationItemKeys.MonthlyAccess]: number | null;
  [DeviceValidationItemKeys.CarrierName]: string;
  [DeviceValidationItemKeys.PdaCharges]: number | null;
  [DeviceValidationItemKeys.DownloadCharges]: number | null;
  [DeviceValidationItemKeys.NetFeatureCharges]: number | null;
  [DeviceValidationItemKeys.AirtimeCharges]: number | null;
  [DeviceValidationItemKeys.CalculatedEquipmentCharges]: number | null;
  [DeviceValidationItemKeys.FoundationAccount]: string;
  [DeviceValidationItemKeys.RoamCharges]: number | null;
  [DeviceValidationItemKeys.DeviceCount]: number;
  [DeviceValidationItemKeys.LdCharges]: number | null;
  [DeviceValidationItemKeys.ActivationCharge]: number | null;
  [DeviceValidationItemKeys.KbCharges]: number | null;
  [DeviceValidationItemKeys.TotalTax]: number | null;
  [DeviceValidationItemKeys.EquipmentCharges]: number | null;
  [DeviceValidationItemKeys.SmsCharges]: number | null;
  [DeviceValidationItemKeys.StatementMonth]: string;
  [DeviceValidationItemKeys.CalculatedTaxes]: number | null;
  [DeviceValidationItemKeys.CalculatedFees]: number | null;
  [DeviceValidationItemKeys.TotalCharges]: number | null;
  [DeviceValidationItemKeys.CalculatedUsageCharges]: number | null;
  [DeviceValidationItemKeys.CalculatedMrc]: number | null;
  [DeviceValidationItemKeys.PeerToPeerCharges]: number | null;
  [DeviceValidationItemKeys.ContractPlanDiscounts]: number | null;
  [DeviceValidationItemKeys.FormattedDeviceType]: FormattedDeviceType;
  [DeviceValidationItemKeys.CarrierAccountNumber]: string;
  [DeviceValidationItemKeys.IsAccountLevelSummary]: boolean;
};

export interface CarrierTotals {
  cnt: number;
  eqp: number;
  mrc: number;
  usage: number;
  tax: number;
  fees: number;
  occ: number;
  total: number;
}

export type AvailableCarriers = "All" | string;
