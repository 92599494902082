import { Tooltip } from "@mui/material";
import { useState, useEffect, useMemo } from "react";

export default function DeviceTypeChartBar({
  maxNegativeValue,
  maxPositiveValue,
  value,
  color,
  label1,
  label2,
}: {
  maxNegativeValue: number;
  maxPositiveValue: number;
  value: number;
  color: string;
  label1: string;
  label2: string;
}) {
  const [currentWidth, setCurrentWidth] = useState(0);

  // Calculate the maximum scale based on the total range
  const scale = useMemo(() => {
    let totalRange = 0;
    if (value > 0) {
      totalRange = maxPositiveValue;
    } else {
      totalRange = Math.abs(maxNegativeValue);
    }
    const magnitude = Math.pow(10, Math.floor(Math.log10(totalRange)));

    return Math.ceil(totalRange / magnitude) * magnitude;
  }, [maxNegativeValue, maxPositiveValue, value]);

  // Calculate percentages based on the total scale
  const positivePercent = value > 0 ? (value / scale) * 100 : 0;
  const negativePercent = value < 0 ? (Math.abs(value) / scale) * 100 : 0;

  const absPercent = Math.abs(positivePercent) || Math.abs(negativePercent);

  useEffect(() => {
    // Set a small delay to ensure the initial 0 width is rendered
    const timer = setTimeout(() => {
      setCurrentWidth(absPercent);
    }, 50);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [absPercent]);

  const tooltipContent = (
    <div className="flex flex-col gap-1 p-1">
      <div className="font-semibold">{label1}</div>
      <div className="text-sm">{label2}</div>
      <div className="text-sm">
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value)}
      </div>
    </div>
  );

  return (
    <Tooltip title={tooltipContent} followCursor placement="top" arrow>
      <div
        style={{
          width: `${currentWidth}%`,
          minWidth: 4,
          backgroundColor: color,
          transition: `width ${Math.max(
            0.15,
            positivePercent / 100
          )}s ease-out`,
        }}
        className={`h-4 cursor-pointer`}
      />
    </Tooltip>
  );
}
