import { Skeleton } from "@mui/material";
import { chartColors } from "../../../Constants/chart-colors";
import ChartLegendLabel from "./ChartLegendLabel";
import SpendingTotalCellTotal from "./SpendingTotalCellTotal";
import { MonthlyInvoiceSpendingTotalItem } from "./types";
import { getCurrencyFormat } from "./utils";
import {
  deviceTypeChartColors,
  deviceTypeLabels,
  deviceTypeOrder,
} from "./constants";
import { FormattedDeviceType } from "../types";

export default function TableFooter({
  isLoading,
  aggregateTotals,
}: {
  isLoading: boolean;
  aggregateTotals: MonthlyInvoiceSpendingTotalItem;
}) {
  const deviceExistsInDataset = (deviceType: FormattedDeviceType) => {
    return aggregateTotals[deviceType] !== 0;
  };

  if (isLoading) {
    return (
      <div className="flex flex-row mt-2">
        <div className="flex flex-row flex-1 items-center gap-4 p-2">
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={80}
              height={20}
            />
          ))}
          <Skeleton
            variant="rectangular"
            width={100}
            height={20}
            className="ml-auto"
          />
        </div>
        <div className="flex flex-row flex-1 gap-2 pr-2">
          {[...Array(8)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={80}
              height={20}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row">
      <div className="flex flex-row flex-1 justify-between items-center">
        <div className="flex flex-row p-2 gap-4 flex-1">
          {deviceTypeOrder.filter(deviceExistsInDataset).map((deviceType) => (
            <ChartLegendLabel
              key={deviceType}
              color={deviceTypeChartColors[deviceType]}
              label={deviceTypeLabels[deviceType]}
            />
          ))}
        </div>

        <div className="p-1 font-bold">TOTAL INVOICE</div>
      </div>
      <div className="flex flex-row flex-1 pr-2">
        <SpendingTotalCellTotal>
          {new Intl.NumberFormat("en-US").format(aggregateTotals.deviceCount)}
        </SpendingTotalCellTotal>
        <SpendingTotalCellTotal>
          {getCurrencyFormat(aggregateTotals.equipmentCharges)}
        </SpendingTotalCellTotal>
        <SpendingTotalCellTotal>
          {getCurrencyFormat(aggregateTotals.calculatedMrc)}
        </SpendingTotalCellTotal>
        <SpendingTotalCellTotal>
          {getCurrencyFormat(aggregateTotals.calculatedUsageCharges)}
        </SpendingTotalCellTotal>
        <SpendingTotalCellTotal>
          {getCurrencyFormat(aggregateTotals.calculatedTaxes)}
        </SpendingTotalCellTotal>
        <SpendingTotalCellTotal>
          {getCurrencyFormat(aggregateTotals.calculatedFees)}
        </SpendingTotalCellTotal>
        <SpendingTotalCellTotal>
          {getCurrencyFormat(aggregateTotals.calculatedOtherCharges)}
        </SpendingTotalCellTotal>
        <SpendingTotalCellTotal>
          {getCurrencyFormat(aggregateTotals.totalCharges)}
        </SpendingTotalCellTotal>
      </div>
    </div>
  );
}
