import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const ScrollIndicator = ({
  showUp,
  showDown,
}: {
  showUp: boolean;
  showDown: boolean;
}) => (
  <div className="absolute top-8 bottom-0 left-4 h-auto flex flex-col justify-between">
    {showUp ? <ArrowUpward className="text-primary-800" /> : <span />}
    {showDown ? <ArrowDownward className="text-primary-800" /> : <span />}
  </div>
);

export default ScrollIndicator;
