const FANSelect = ({
  fanAccounts,
  selectedFan,
  handleFanChange,
}: {
  fanAccounts: string[];
  selectedFan: string;
  handleFanChange: (value: string) => void;
}) => {
  return (
    <select
      value={selectedFan}
      onChange={(event) => handleFanChange(event.target.value)}
      className="w-28 px-2 py-1 text-xs text-white bg-transparent border border-white rounded-md 
        focus:outline-none focus:ring-2 focus:ring-white/50
        appearance-none bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00LjcgNi4zTDggOS42bDMuMy0zLjNjLjQtLjQgMS0uNCAxLjQgMCAuNC40LjQgMSAwIDEuNGwtNCA0Yy0uNC40LTEgLjQtMS40IDBsLTQtNGMtLjQtLjQtLjQtMSAwLTEuNC40LS40IDEtLjQgMS40IDB6Ii8+PC9zdmc+')] bg-[length:16px] bg-no-repeat bg-[center_right_4px]"
    >
      <option value="all" className="bg-primary-800">
        All FANs
      </option>
      {fanAccounts.map((fan) => (
        <option key={fan} value={fan} className="bg-primary-800">
          {fan}
        </option>
      ))}
    </select>
  );
};

export default FANSelect;
