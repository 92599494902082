import { chartColors } from "../../ChartsDashboard/constants";
import { FormattedDeviceType } from "../types";

export const deviceTypeOrder = [
  FormattedDeviceType.Smart,
  FormattedDeviceType.Phone,
  FormattedDeviceType.Data,
  FormattedDeviceType.Tablet,
  FormattedDeviceType.Wearable,
  FormattedDeviceType.Pager,
];

export const deviceTypeLabels: Record<FormattedDeviceType, string> = {
  [FormattedDeviceType.Smart]: "SmartPhone",
  [FormattedDeviceType.Phone]: "Phone",
  [FormattedDeviceType.Data]: "Data",
  [FormattedDeviceType.Tablet]: "Tablet",
  [FormattedDeviceType.Wearable]: "Wearable",
  [FormattedDeviceType.Pager]: "Pager",
};

export const deviceTypeChartColors: Record<FormattedDeviceType, string> = {
  [FormattedDeviceType.Smart]: chartColors[0],
  [FormattedDeviceType.Phone]: chartColors[1],
  [FormattedDeviceType.Data]: chartColors[2],
  [FormattedDeviceType.Tablet]: chartColors[3],
  [FormattedDeviceType.Wearable]: chartColors[4],
  [FormattedDeviceType.Pager]: "#baa55a",
};
